<template>
  <div id="curso">
    <Tela />
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreAreas, useStoreCursos } from '@stores'
import Tela from '@components/pages/curso/partials/Tela.vue'

const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const router = useRouter()

async function carregarCurso() {
  const hotlinkCurso = router.currentRoute.value.params.hotlinkCurso
  await storeCursos.receberPorHotlink(hotlinkCurso)

  document.title = storeCursos.curso?.nome
}

watch(
  () => storeAreas.area,
  (area) => {
    if (area) {
      carregarCurso()
    }
  },
  { immediate: true }
)
</script>

<style scoped>
#curso {
  padding: 0 0 0 300px;
}
@media screen and (max-width: 1000px) {
  #curso {
    padding: 50px 0 0 0;
  }
}
</style>
