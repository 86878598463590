import { api } from './axios'

export default {
  receberPorHotlink(hotlink) {
    const method = 'GET'
    const url = `areas/hotlink/${hotlink}`

    return api(method, url, null, null)
  },
}
