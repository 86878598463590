/* ---- Constants ---- */

const ROTAS_PRE_AUTENTICACAO = ['/senha', '/', '/loading']

/* ---- Methods ---- */

function verificarRotaInterna() {
  const rotaAtual = extrairRota()
  const rotaEncontrada = ROTAS_PRE_AUTENTICACAO.includes(`/${rotaAtual}`)
  if (rotaEncontrada) return false
  return true
}

/* ---- Aux Functions ---- */

function detectarDominio() {
  const dominio = window.location.hostname
  const levelMember = 'mber.live'
  const localhost = 'localhost'

  if (dominio === localhost) return 'localhost'
  if (dominio === levelMember) return 'levelmember'
  return 'externo'
}

function extrairRota() {
  const path = window.location.pathname
  const parametros = path.split('/')

  const dominio = detectarDominio()
  if (dominio === 'externo') return parametros[1]

  const parametro2 = parametros.length > 2
  if (parametro2) return parametros[3]
  return ''
}

export default { verificarRotaInterna }
