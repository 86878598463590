import { defineStore } from "pinia"
import { useStoreAlerta } from "@stores"
import apiCursos from "../api/aluno/api-cursos"

export const useStoreCursos = defineStore('storeCursos', {
  state: () => {
    return {
      cursos: null,
      curso: null,
    }
  },
  actions: {
    async receberTodos() {
      try {
        const resp = await apiCursos.receberTodos()
        this.cursos = resp
        return resp
      }
      catch (error) {
        this.cursos = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorHotlink(hotlink) {
      try {
        const resp = await apiCursos.receberPorHotlink(hotlink)
        this.curso = resp
        return true
      }
      catch (error) {
        this.curso = null
        return false
      }
    },

    async receberPorId() {
      try {
        const resp = await apiCursos.receberPorId()
        this.curso = resp
        return true
      }
      catch (error) {
        this.curso = null
        return false
      }
    },

  },
})
