import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()
const idArea = helperToken.idArea()
const idAluno = helperToken.idAluno()

export default {

  receberTodos(idCurso, idModulo, idAula) {
    const method = 'GET'
    const url = `comentarios/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}/${idAluno}`

    return api(method, url, null, token)
  },

  comentar(idCurso, idModulo, idAula, payload) {
    const method = 'POST'
    const url = `comentarios/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}/${idAluno}`
    const dados = payload

    return api(method, url, dados, token)
  },

  deletar(idCurso, idModulo, idAula, idComentario) {
    const method = 'DELETE'
    const url = `comentarios/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}/${idComentario}/${idAluno}`
    const dados = {}

    return api(method, url, dados, token)
  },

  responder(idCurso, idModulo, idAula, idComentario, payload) {
    const method = "PATCH"
    const url = `comentarios/responder/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}/${idComentario}/${idAluno}`
    const dados = payload

    return api(method, url, dados, token)
  },
}
