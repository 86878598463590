import { defineStore } from "pinia"
import { useStoreAlerta } from "@stores"
import apiAulas from "../api/aluno/api-aulas"

export const useStoreAulas = defineStore('storeAulas', {
  state: () => {
    return {
      aulas: null,
      aula: null,
    }
  },
  actions: {
    async receberTodos(idCurso, idModulo) {
      try {
        const resp = await apiAulas.receberTodos(idCurso, idModulo)
        this.aulas = resp
        return resp
      }
      catch (error) {
        this.aulas = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorHotlink(idCurso, idModulo, hotlink) {
      try {
        const resp = await apiAulas.receberPorHotlink(idCurso, idModulo, hotlink)
        this.aula = resp
        return true
      }
      catch (error) {
        this.aula = null
        return false
      }
    },

    async receberPorId(idCurso, idModulo, idAula) {
      try {
        const resp = await apiAulas.receberPorId(idCurso, idModulo, idAula)
        this.aula = resp
        return true
      }
      catch (error) {
        this.aula = null
        return false
      }
    },

  },
})
