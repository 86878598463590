import { defineStore } from "pinia"
import { useStoreAlerta } from "@stores"
import apiModulos from "../api/aluno/api-modulos"

export const useStoreModulos = defineStore('storeModulos', {
  state: () => {
    return {
      modulos: null,
      modulo: null,
    }
  },
  actions: {
    async receberTodos(idCurso) {
      try {
        const resp = await apiModulos.receberTodos(idCurso)
        this.modulos = resp
        return resp
      }
      catch (error) {
        this.modulos = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorHotlink(idCurso, hotlink) {
      try {
        const resp = await apiModulos.receberPorHotlink(idCurso, hotlink)
        this.modulo = resp
        return true
      }
      catch (error) {
        this.modulo = null
        return false
      }
    },

    async receberPorId(idCurso, idModulo) {
      try {
        const resp = await apiModulos.receberPorId(idCurso, idModulo)
        this.modulo = resp
        return true
      }
      catch (error) {
        this.modulo = null
        return false
      }
    },

  },
})
