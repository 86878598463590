import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()
const idArea = helperToken.idArea()
const idAluno = helperToken.idAluno()

export default {
  receberPorHotlink(idCurso, idModulo, hotlink) {
    const method = 'GET'
    const url = `aulas/hotlink/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${hotlink}/${idAluno}`

    return api(method, url, null, token)
  },

  receberPorId(idCurso, idModulo, idAula) {
    const method = 'GET'
    const url = `aulas/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}/${idAluno}`

    return api(method, url, null, token)
  },

  receberTodos(idCurso, idModulo) {
    const method = 'GET'
    const url = `aulas/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAluno}`

    return api(method, url, null, token)
  },
}
