<template>
  <div id="login">
    <Tela />
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue'
import { helperToken } from '@helpers'
import { useStoreAreas } from '@stores'
import Tela from '@components/pages/login/partials/Tela.vue'

const storeAreas = useStoreAreas()

function bloquearRotaSeLogado() {
  if (!helperToken.detectarTokenOk()) return
  const hotlink = storeAreas.area?.hotlink
  if (hotlink) {
    setTimeout(() => {
      window.location.assign(`/${hotlink}/loading`)
    }, 1000);
  }
}

watch(
  () => storeAreas.area,
  (novaArea) => {
    if (novaArea) {
      bloquearRotaSeLogado()
    }
  },
  { immediate: true }
)

onMounted(() => {
  bloquearRotaSeLogado()
})
</script>

<style scoped></style>
