import { defineStore } from "pinia"
import { useStoreAlerta } from "@stores"
import apiAulasConcluidas from "../api/aluno/api-aulas-concluidas"

export const useStoreAulasConcluidas = defineStore('storeAulasConcluidas', {
  state: () => {
    return {
      aulasConcluidas: null,
    }
  },
  actions: {

    async receberTodos(idCurso) {
      try {
        const resp = await apiAulasConcluidas.receberTodos(idCurso)
        this.aulasConcluidas = resp
        return resp
      }
      catch (error) {
        this.aulasConcluidas = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async concluir(idCurso, idModulo, idAula, payload) {
      try {
        const resp = await apiAulasConcluidas.concluir(idCurso, idModulo, idAula, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      }
      catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },
  },
})
