import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()
const idArea = helperToken.idArea()
const idAluno = helperToken.idAluno()

export default {
  receberTodos(idCurso) {
    const method = 'GET'
    const url = `aulas-concluidas/${idProdutor}/${idArea}/${idCurso}/${idAluno}`

    return api(method, url, null, token)
  },
  concluir(idCurso, idModulo, idAula, payload) {
    const method = 'PATCH'
    const url = `aulas-concluidas/concluir/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}/${idAluno}`
    const dados = payload

    return api(method, url, dados, token)
  },
}
