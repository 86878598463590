<template>
  <div id="modulo">
    <Tela />
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreCursos, useStoreModulos } from '@stores'
import Tela from '@components/pages/modulo/partials/Tela.vue'

const storeModulos = useStoreModulos()
const storeCursos = useStoreCursos()
const router = useRouter()

async function carregarModulo() {
  let idCurso = storeCursos.curso?._id

  if (!idCurso) {
    const hotlinkCurso = router.currentRoute.value.params.hotlinkCurso
    await storeCursos.receberPorHotlink(hotlinkCurso)
    idCurso = storeCursos.curso?._id
  }

  if (idCurso) {
    const hotlinkModulo = router.currentRoute.value.params.hotlinkModulo
    await storeModulos.receberPorHotlink(idCurso, hotlinkModulo)

    document.title = storeModulos.modulo?.nome
  }
}

watch(
  () => [router.currentRoute.value.params.hotlinkCurso],
  async (hotlinkCurso) => {
    if (hotlinkCurso && !storeCursos.curso?._id) {
      await storeCursos.receberPorHotlink(hotlinkCurso)
    }
    carregarModulo()
  },
  { immediate: true }
)
</script>

<style scoped>
#modulo {
  padding: 0 0 0 300px;
}
@media screen and (max-width: 1000px) {
  #modulo {
    padding: 50px 0 0 0;
  }
}
</style>
