import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()
const idArea = helperToken.idArea()
const idAluno = helperToken.idAluno()

export default {
  receberPorHotlink(idCurso, hotlink) {
    const method = 'GET'
    const url = `modulos/hotlink/${idProdutor}/${idArea}/${idCurso}/${hotlink}/${idAluno}`

    return api(method, url, null, token)
  },

  receberPorId(idCurso, idModulo) {
    const method = 'GET'
    const url = `modulos/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAluno}`

    return api(method, url, null, token)
  },

  receberTodos(idCurso) {
    const method = 'GET'
    const url = `modulos/${idProdutor}/${idArea}/${idCurso}/${idAluno}`

    return api(method, url, null, token)
  },
}
