import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()

export default {
  entrar(idProdutor, idArea, payload) {

    const method = 'POST'
    const url = `autenticacao/entrar/${idProdutor}/${idArea}`
    const dados = payload

    return api(method, url, dados, token)
  }
}
