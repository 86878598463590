<template>
  <div id="aula">
    <Tela />
  </div>
</template>

<script setup>
import { watch, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreCursos, useStoreModulos, useStoreAulas } from '@stores'

import Tela from '@components/pages/aula/partials/Tela.vue'

const storeAulas = useStoreAulas()
const storeModulos = useStoreModulos()
const storeCursos = useStoreCursos()
const emitter = inject('emitter')
const router = useRouter()

async function carregarAula() {
  let idCurso = storeCursos.curso?._id
  let idModulo = storeModulos.modulo?._id

  if (!idCurso) {
    const hotlinkCurso = router.currentRoute.value.params.hotlinkCurso
    await storeCursos.receberPorHotlink(hotlinkCurso)
    idCurso = storeCursos.curso?._id
  }

  if (idCurso && !idModulo) {
    const hotlinkModulo = router.currentRoute.value.params.hotlinkModulo
    await storeModulos.receberPorHotlink(idCurso, hotlinkModulo)
    idModulo = storeModulos.modulo?._id
  }

  if (idCurso && idModulo) {
    const hotlinkAula = router.currentRoute.value.params.hotlinkAula
    await storeAulas.receberPorHotlink(idCurso, idModulo, hotlinkAula)

    document.title = storeAulas.aula?.nome

    emitter.emit('atualizarComentarios')
  }
}

watch(
  () => [router.currentRoute.value.params.hotlinkCurso, router.currentRoute.value.params.hotlinkModulo],
  async ([hotlinkCurso, hotlinkModulo]) => {
    if (hotlinkCurso && !storeCursos.curso?._id) {
      await storeCursos.receberPorHotlink(hotlinkCurso)
    }
    if (storeCursos.curso?._id && hotlinkModulo && !storeModulos.modulo?._id) {
      await storeModulos.receberPorHotlink(storeCursos.curso._id, hotlinkModulo)
    }
    carregarAula()
  },
  { immediate: true }
)
</script>

<style scoped>
#aula {
  padding: 0 0 0 300px;
}
@media screen and (max-width: 1000px) {
  #aula {
    padding: 50px 0 0 0;
  }
}
</style>
