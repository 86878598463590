import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()
const idArea = helperToken.idArea()
const idAluno = helperToken.idAluno()

export default {
  receberPorHotlink(hotlink) {
    const method = 'GET'
    const url = `cursos/hotlink/${idProdutor}/${idArea}/${hotlink}/${idAluno}`

    return api(method, url, null, token)
  },

  receberPorId() {
    const method = 'GET'
    const url = `cursos/${idProdutor}/${idArea}/${idCurso}/${idAluno}`

    return api(method, url, null, token)
  },

  receberTodos() {
    const method = 'GET'
    const url = `cursos/${idProdutor}/${idArea}/${idAluno}`

    return api(method, url, null, token)
  },
}
